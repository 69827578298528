/*global epidor*/
/*global $*/
/*global jQuery*/

(function(){
	window.epidor={};

	epidor.init=function() {
		
		// disable mousewheel on a input number field when in focus
		// (to prevent Cromium browsers change the value when scrolling)
		$('form').on('focus', 'input[type=number]', function (e) {
		  $(this).on('mousewheel.disableScroll', function (e) {
		    e.preventDefault()
		  })
		})
		$('form').on('blur', 'input[type=number]', function (e) {
		  $(this).off('mousewheel.disableScroll')
		})

		var refresh_header_mb =function() {
			var header_mb=$('.navbar-fixed-top').height()+20;
			$('body>div>header').css('margin-bottom',header_mb);
		}

		$(window).on('resize', function(){
			refresh_header_mb();
		});

		refresh_header_mb();

		// ajax submit
		$(document).on('click','*[type=submit][data-ajaxtarget]', epidor.ajaxsubmit);

		$(document).on('click','[data-confirm]', function(e){
			return window.confirm($(this).data('confirm'));
		});


		$('div.histo_graph').each(function(){
			var obj=$(this);
			epidor.initHistoGraph(obj);

			window.addEventListener('livetableupdated', function (e) {
				epidor.initHistoGraph(obj);
			});

		});


		$('form').each(function(){
			var cancel_link=$(this).find('.cancelurl');
			if(cancel_link.length) {
				epidor.cancel_url=cancel_link.prop('href');
				epidor.alert_on_close=false;

				$(this).on('change',':input',function(){
					epidor.alert_on_close=true;
				});

				$(this).on('submit',function(){
					epidor.alert_on_close=false;
				})

				window.onbeforeunload = function() {
					if (epidor.cancel_url) {
						jQuery.ajax({url:epidor.cancel_url,async: false,});
					}
					if (epidor.alert_on_close){
						return "Les modifications ne seront pas enregistrées, êtes vous sûr de vouloir quitter la page ?";
					}
				};
			}
		});


		$('*[data-epidorTBalerts]').each(epidor.init_TB_alerts);
		$('*[data-epidorTBactes]').each(epidor.init_TB_actes);
		$('*[data-epidorTBtosend]').each(epidor.init_TB_tosend);
		$('*[data-epidorTBavis]').each(epidor.init_TB_avis);

		$('body').on('change','[data-toggleclass]',function(){
			var d=$(this).data();
			$(d.toggleclassTarget).toggleClass(d.toggleclass);
		});

		$('*[data-latlng]').each(function(){
			latlng=$(this).data('latlng');
			$(this).html('<a href="http://maps.google.com/maps?q='+latlng[0]+','+latlng[1]+'" target=_blank>'
				+latlng[0]+', '+latlng[1]
				+'</a>'
				+'<pre>idée: ajouter fonds IGN: ortho, scan, cadastre</pre>');
			var div=$('<!--div class="map"></div-->').appendTo($(this));




			var OpenStreetMap_France = L.tileLayer('http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
				maxZoom: 20,
				//attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
			});

			var Esri_WorldImagery = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
				//attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
			});

			var OpenTopoMap = L.tileLayer('http://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
				maxZoom: 17,
				//attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
			});

			var Esri_WorldTopoMap = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
				//attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
			});

			var map = L.map(div[0], {
				layers: [OpenStreetMap_France,Esri_WorldImagery]
			}).setView(latlng, 15);

			var title=$(this).prop('title');
			var marker    = L.marker(latlng);
			if (title!=undefined) marker.bindPopup(title);
			marker.addTo(map);

			var baseMaps = {
				"OpenStreetMap": OpenStreetMap_France,
				//"OpenTopoMap": OpenTopoMap,
				"Esri WorldImagery": Esri_WorldImagery,
				"Esri WorldTopoMap":Esri_WorldTopoMap,
			};

			L.control.layers(baseMaps, []).addTo(map);

			Esri_WorldImagery.addTo(map);

		});

		epidor.refresh();
		epidor.initSearch();
		epidor.initLiveTable();

	}; // fin init


	epidor.refresh=function() {
		$('[data-toggle="tooltip"]').tooltip();//.tooltip('show');
		$('[data-toggle="popover"]').popover();
		$('a[data-toggle="popover"]').click(function(e){e.preventDefault();});


		// datepicker

		$('*[data-toggle=datepicker]').each(function(){
			var options={
				format: "dd/mm/yyyy",
				weekStart: 1,
				maxViewMode: 3,
				todayBtn: "linked",
				clearBtn: true,
				language: "fr",
				daysOfWeekHighlighted: "0,6",
				calendarWeeks: true,
				autoclose: true,
				todayHighlight: true
			};
			options.multidate=$(this).hasClass('multidate');

			$(this).datepicker(options);
		});

		// numeric range

		$('.input-numericrange').each(function(){
			var d=$(this);
			var i_min=d.find('input.input-numericrange-min');
			var i_max=d.find('input.input-numericrange-max');
			i_min.on('change',function(){
				if (i_min.val()>i_max.val())
					i_max.val(i_min.val());
			});
			i_max.on('change',function(){
				if (i_max.val()<i_min.val())
					i_min.val(i_max.val());
			});
		});

		
		
		//select 2
		$.fn.select2.defaults.set( "theme", "bootstrap" );
		//$('.select2').select2();
		
		$('.select2').each(function(){
			var obj=$(this);
			var options={};
			
			if(obj.data('autocomplete_url')!==undefined) {
				console.log('kjjkohgkog');
				//**
				options={
				  
				  ajax: {
				    url: obj.data('autocomplete_url'),
				    dataType: 'json',
				    delay: 250,
				    data: function (params) {
				      return {
				        q: params.term, // search term
				        page: params.page
				      };
				    },
				    processResults: function (data, params) {
				      // parse the results into the format expected by Select2
				      // since we are using custom formatting functions we do not need to
				      // alter the remote JSON data, except to indicate that infinite
				      // scrolling can be used
				      console.log(3);
				      params.page = params.page || 1;
				
				      return {
				        results: data.items,
				        pagination: {
				          more: (params.page * 30) < data.total_count
				        }
				      };
				    },
				    cache: true
				  },
				  escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
				  minimumInputLength: 0,
				  
				 // templateResult: formatRepo, // omitted for brevity, see the source of this page
				 // templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
				}
		
				//**
			}
			
			obj.select2(options);
			
			
			
		});// fin each .select2
		


		$("[data-clearselect]").on("click", function (e) {
			e.preventDefault();
			$($(this).data('clearselect')).val(null).trigger("change");
		});


		$("[data-select-target]").on("click", function (e) {
			e.preventDefault();
			var target=$($(this).data('select-target'));

			if ($(this).data('select-add')) {
				var r=target.val();
				target.val(r.concat( eval($(this).data('select-add'))));
			}
			if ($(this).data('select-set')) {
				target.val(eval($(this).data('select-set')));
			}
			target.trigger("change");
		});

		$('.autocomplete').select2(
		{
			ajax:{

				url: function() {
					return (null===$(this).data('url'))?$(this).data('url'):'';
				},
				dataType: 'json',
				delay: 250,
				minimumInputLength: 1,

				data: function (params) {
					return {
						'searchfield':$(this).data('searchfield'),
						'q':params.term,
						'page':params.page
					}
				},


				processResults: function (data, params) {

					params.page = params.page || 1;

					return {
						results: data.items
					};
				},
				cache: true
			},
	  escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
	  minimumInputLength: 1,
	  templateResult: function(repo) {
	  	if (repo.loading) return repo.text;
	  	return "<div class='select2-result-repository clearfix'>" +repo.html+ "</div>";
	  },
	  templateSelection: function(repo) {
	  	return repo.html;
	  }
	});

	}

	$( document ).ready(function() {
		epidor.init();
		console.log('yeah');
	});

	$('.h_fixed').each(function(){
		$(this).data('ori_left',$(this).css('left'));
	});


	epidor.initSearch=function() {
		var form=$('#searchform');
		var url=form.prop('action');
		var input=form.find('input[name=r]');
		var last_s=null;
		var cache={};
		var query=null;
		var timeout=null;
		var div_res=$('<div class="search_ajax"><div class="list-group search-result"></div><button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
		div_res.hide();
		div_res.appendTo(form);
		var target=div_res.find('.search-result');

		div_res.find('.close').click(function(){
			input.val('');
			div_res.slideUp();
			clearTimeout(timeout);
			if (query) query.abort;
		});

		var update=function() {
			if (query) query.abort;
			clearTimeout(timeout);

			timeout=setTimeout(function(){
				var r=input.val();

				if (r.length>2) {

					if(undefined!=cache[r]) {
						success(cache[r]);
					} else {
						div_res.slideDown();
						target.find('.loading').remove();
						target.prepend($('<div class="loading"><strong>'+r+'</strong> recherche en cours <i class="fa fa-spinner fa-pulse fa-fw"></i></div>'));

						query=$.ajax({
							dataType: "json",
							url: url,
							data: {r: r},
							success: success
						});
					}
				} else {
					div_res.slideUp();
				}
			},100);
		}

		var success=function(data) {
			query=null;
			var r=input.val();
			cache[r]=data;
			var html='';
			jQuery.each(data,function(k,l){
				if (k<10)
					html+=l.html;
			});
			if (data.length>=10)
				html+='<div class="text-right"><a class="btn btn-default btn-seemore" href="'+url+'?r='+r+'">voir les autres résultats</a></div>';

			if (data.length==0)
				html+='<div class="loading"><i class="fa fa-frown-o"></i> aucun résultat trouvé pour <strong>'+r+'</strong></div>';

			target.html(html);
		}

		input.keydown(update);
		input.focus(update);
	}



	epidor.ajaxsubmit=function(evt) {
		var btn=$(this);
		evt.preventDefault();
		if (btn.data('ajaxtarget-confirm')) {
			var c=window.confirm(btn.data('ajaxtarget-confirm'));
			if (!c) return null;
		}

		var form=btn.parents('form').first();
		var target=$(btn.data('ajaxtarget'));
		var msg=epidor.showMessage(btn.val(),'loading');
		var data=form.serialize();
		data+='&'+btn.prop('name')+'='+encodeURI(btn.val());
		$('<span class="loading"><i class="fa fa-spinner fa-pulse fa-fw"></i>  chargement en cours …</span>').insertAfter(btn);
		btn.hide();



		jQuery.ajax({
			url: form.prop('action'),
			data: data,
			method: form.prop('method'),
			dataType: 'json'
		})
		.done(function(data){
			switch(data.status.value) {
				case 'success':
				msg.update(data.status.success,'success');
				break;
				case 'error':
				msg.update(data.status.error,'danger');
				break;
				case 'danger':
				msg.update(data.status.danger,'danger');
				break;
				case 'info':
				msg.update(data.status.info,'info');
				break;
				default :
				msg.update('erreur','danger');
				break;
			}

			var scroll = $(window).scrollTop();
			target.replaceWith(data.data._HTML);
			$("html").scrollTop(scroll);
			epidor.refresh();
		});

	}

	epidor.showMessage=function(message,status) {

		var msg={
			obj: null
		};

		var container=$('#epidor-msg');
		if (!container.length) container=$('<ul id="epidor-msg"></ul>').appendTo('body');

		var obj=$('<li></li>');
		obj.appendTo(container);
		msg.obj=obj;

		msg.show=function() {
			msg.obj.fadeIn(250);
			if (msg.status!='loading')
				setTimeout(function(){ msg.hide(); }, 750);
			return msg;
		}

		msg.hide=function() {
			msg.obj.fadeOut(750);
		}

		msg.update=function(message,status) {
			msg.message=message;
			msg.status=status;
			var fmessage=message;
			if('loading'==status) fmessage='<i class="fa fa-spinner fa-pulse fa-fw"></i> '+fmessage;
			msg.obj.html(fmessage);
			msg.obj.removeClass().addClass(status);
			return msg.show();
		}

		msg.update(message,status);





		return msg;
	}



	epidor.initLiveTable=function() {
		var LT_form=$('form.livetable-form');


		LT_form.on("click",".select_input_checkall", function(evt){
			evt.preventDefault();
			var n_val= LT_form.find('input[name="s[]"]').length > LT_form.find('input[name="s[]"]:checked').length;
			LT_form.find('input[name="s[]"]').prop('checked',n_val);
			refresh_checked_style();
		})

		.on("click",".selectinput", function(evt){
			evt.preventDefault();
			var checkbox= $(this).find('input');
			checkbox.prop('checked', !checkbox.prop('checked'));
			refresh_checked_style();
		})
		.on("click",".selectinput input", function(evt){
			evt.stopPropagation();
			refresh_checked_style();
		});



		var refresh=function(){


			var stateObj = LT_form.serialize();

			//ajoute les select multiple null a serialize
			var stateObj_empty_multi=''
			LT_form.find("select[multiple]").map(function(){
				if(this.value == "") stateObj_empty_multi+='&'+this.name.replace('[]','_ALL')+'=1';
			});

			stateObj+=stateObj_empty_multi;

			var url=LT_form.action;
			if (undefined==url) url=document.URL.split('?')[0];
			load(url+'?'+decodeURI(stateObj));

			var evt = new CustomEvent('livetableupdated', {});
			window.dispatchEvent(evt);

		}

		var load=function(url) {
			$('body').addClass('LT_loading');
			jQuery.getJSON(url,null,function(d){
				if (undefined!=d.html) {
					LT_form.find('div.livetable').html(d.html);
					$('.LT_loading').removeClass('LT_loading');
				}
			})
			var title=null;
			history.replaceState(null,title,url);
			refresh_checked_style();
			LT_form.trigger( "LT:loaded" );
		}


		var refresh_checked_style=function(){
			LT_form.find('input[name="s[]"]').each(function(){
				if ($(this).prop('checked')) {
					$(this).parents('tr').addClass('selected');
				} else {
					$(this).parents('tr').removeClass('selected');
				}
			});

			var selected_count=LT_form.find('input[name="s[]"]:checked').length;

			if (selected_count>0) {
				LT_form.find('.select_actions').slideDown();
			} else  {
				LT_form.find('.select_actions').fadeOut();
			}

			LT_form.find('.nb-select').html(selected_count);
		}


		LT_form.on('change','select,input',function(){
			if($(this).hasClass('norefresh')) {
				refresh_checked_style();
			} else  {
				refresh();
			}

		});


		LT_form.on('click','th a.orderby,ul.pagination a',function(e){
			e.preventDefault();
			load(e.target.href);
		});

		refresh_checked_style();

	};




	epidor.init_TB_alerts=function(){
		var div=$(this);
		var group=div.data('epidortbalerts').group;
		var sectors=div.data('epidortbalerts').sectors;
		var url=div.data('epidortbalerts').url;
		jQuery.getJSON(url,{'group':group, 'sectors':sectors},function(d){
			if (undefined!=d.data._HTML) {
				div.html(d.data._HTML);
				div.find('[data-toggle="collapse"]').collapse();
			}
		});
	};




	epidor.init_TB_actes=function(){
		var div=$(this);
		var group=div.data('epidortbactes').group;
		var sectors=div.data('epidortbactes').sectors;
		var url=div.data('epidortbactes').url;
		jQuery.getJSON(url,{'group':group, 'sectors':sectors},function(d){
			if (undefined!=d.data._HTML) {
				div.html(d.data._HTML);
			}
		});
	};



	epidor.init_TB_tosend=function(){
		var div=$(this);
		var group=div.data('epidortbtosend').group;
		var sectors=div.data('epidortbtosend').sectors;
		var url=div.data('epidortbtosend').url;
		jQuery.getJSON(url,{'group':group, 'sectors':sectors},function(d){
			if (undefined!=d.data._HTML) {
				div.html(d.data._HTML);
			}
		});
	};

	epidor.init_TB_avis=function(){
		var div=$(this);
		var group=div.data('epidortbavis').group;
		var sectors=div.data('epidortbavis').sectors;
		var url=div.data('epidortbavis').url;
		jQuery.getJSON(url,{'group':group, 'sectors':sectors},function(d){
			if (undefined!=d.data._HTML) {
				div.html(d.data._HTML);
			}
		});
	};


	/**
	 * Grphique
	 * @param  {[type]} obj [description]
	 * @return {[type]}     [description]
	 */
	 epidor.initHistoGraph=function(obj){
	 	var svg;
	 	var form;
	 	if (undefined==obj){
	 		obj=$('div.histo_graph');
	 	}
	 	svg=obj.find('svg');
	 	form=obj.find('form');
	 	if (!form.length) {
	 		form=$('<form>').appendTo(obj);
	 	}


		/**
		 * La clé de base
		 * @type {String}
		 */
		 var default_key='count_by_status';
		/**
		 * Nombre d'années -1
		 * @type {Number}
		 */
		 var duration = 5;
		 var max_year=moment().year();
		 var min_year=max_year-duration;
		 var data=[];
		/**
		 * Les clées des données
		 * @type {Array}
		 */
		 var storage_key=[];
		/**
		 * Données affiché
		 * @type {Array}
		 */
		 var formatted=[];
		/**
		 * Url
		 * @type {[type]}
		 */
		 var queryurl=obj.data('queryurl');
		/**
		 * Les paramétre de l'url
		 * @type {[type]}
		 */
		 var params_string=url().split('?')[1];

		 if (undefined==params_string){
		 	params_string='action=getGraph';
		 } else {
		 	params_string+='&action=getGraph';
		 }



		 var setkey=function(new_value) {
		 	default_key=new_value;
		 	buildGraph();
		 }

		 var getkey=function(element) {
		 	return(element[default_key]);
		 }
		/**
		 * Verifie que le mode demander n'est pas activé si oui reset le graphique
		 * @return {[type]}       [description]
		 */
		 var activ_mod =function(){
		 	var input_val = $('input[name=group]:checked').val();
		 	if (storage_key[input_val].slug!=default_key) {
		 		setkey(storage_key[input_val].slug);
		 	}
		 }
		/**
		 * Création des inputs radio pour le changement de mode
		 * @return {[type]} [description]
		 */
		 var factory_html=function(){
		 	form.empty();
		 	var checked;
		 	for (var index in storage_key) {
		 		checked="";
		 		if(storage_key[index].slug==default_key){
		 			checked="checked";
		 		}
		 		form.append(
		 			'<div class="radio-inline">'+
		 			'<label class="radio_graph">'+
		 			'<input type="radio" value="'+index+'" name="group" '+checked+'>'+storage_key[index].name+
		 			'</label>'+
		 			'</div>');
		 	}
		 	form.find('input[name=group]').click(activ_mod);
		 }
		/**
		 * Reset le graphique
		 * @return {[type]} [description]
		 */
		 var reset=function(){
		 	console.log('test');
		 	epidor.initHistoGraph();
		 }
		/**
		 * Construit l'objet pour le graphique
		 * @return {[type]} [description]
		 */
		 var buildGraph=function() {
		 	console.log("build");
		 	var tab;
		 	var first=true;
		 	var element;
		 	storage_key=[];
		 	formatted=[];
		 	for (var index in data) {
		 		element = data[index];
		 		if (first) {
		 			for (var index_b in element) {
		 				storage_key.push({slug:index_b});
		 			}
		 			element=getkey(element);
		 			for (var index_b in element) {
		 				formatted.push({key:index_b,values:[]});
		 				first=false;
		 			}
		 		} else {
		 			element=getkey(element);
		 		}
		 		for (var index_b in element) {

		 			for (var index_f = 0; index_f < formatted.length; index_f++) {
		 				if (formatted[index_f].key==index_b) {
		 					formatted[index_f].values.push({x:parseInt(index),y:element[index_b]});
		 				}
		 			}
		 		}
		 	}
		 	set_lang();
		 }
		/**
		 * Initialise et affiche le graphique
		 * @return {[type]} [description]
		 */
		 var init_graph=function(){
		 	console.log("init graph");
		 	nv.addGraph(function() {
		 		var chart = nv.models.multiBarChart()
		 		.duration(350)
		 		.reduceXTicks(true)
		 		.rotateLabels(0)
		 		.stacked(true)
		 		.showControls(true)
		 		.groupSpacing(0.1)
		 		;
		 		chart.controlLabels({"stacked":"cumulé","grouped":"séparé"})
		 		chart.xAxis.tickFormat(d3.format('f'));
		 		chart.yAxis.tickFormat(function(d) {
		 			var format = d3.format(" .d");

		 			if(default_key=="amount_by_status"){
		 				return format(d) +"€";
		 			}
		 			return format(d);

		 		});

		 		d3.select(obj.find('svg')[0])
		 		.datum(formatted)
		 		.call(chart);
		 		nv.utils.windowResize(chart.update);

		 		return chart;
		 	});
		 	factory_html();
		 }
		/**
		 * Verifie ci toute les données sont là
		 * @return {[type]} [description]
		 */
		 var checkdata=function() {
		 	var verif=0;
		 	data.forEach(function(element) {
		 		verif++;
		 	}, this);
		 	verif-=1;
		 	if (verif==duration) {
		 		buildGraph();
		 	}
		 }
		/**
		 * Recupére la valeur d'une année
		 * @param  {[type]} year [description]
		 * @return {[type]}      [description]
		 */
		 var getYearData= function(year) {
		 	var url=queryurl.replace('***YEAR***',year);
		 	$.getJSON(url, params_string, function(this_data){
		 		data[year]=this_data;
		 		checkdata();
		 	});
		 }
		/**
		 * Definition des labels
		 */
		 var set_lang=function(){
		 	for (var index = 0; index < formatted.length; index++) {
		 		var element = formatted[index].key;
		 		switch (formatted[index].key) {
		 			case "projet":
		 			formatted[index].key="Projets";
		 			break;
		 			case "envoye":
		 			formatted[index].key="Envoyés";
		 			break;
		 			case "paye":
		 			formatted[index].key="Payés";
		 			break;
		 			case "a_valider":
		 			formatted[index].key="A valider";
		 			break;
		 			case "done":
		 			formatted[index].key="Fait";
		 			break;
		 			case "todo":
		 			formatted[index].key="A faire";
		 			break;
		 			default:

		 			break;
		 		}
		 	}

		 	for (var index = 0; index < storage_key.length; index++) {
		 		var element = storage_key[index];
		 		switch (element.slug) {
		 			case "amount_by_status":
		 			element.name="Montant"
		 			break;
		 			case "count_by_status":
		 			element.name="Nombre"
		 			break;
		 			default:
		 			element.name=element.slug;
		 			break;
		 		}
		 	}
		 	init_graph();
		 }
		/**
		 * Demande les informations des années
		 * @param  {[type]} var i             [description]
		 * @return {[type]}     [description]
		 */
		 for (var i = min_year; i <= max_year; i++) {
		 	getYearData(i);
		 }

/**
 * End epidor.initHistoGraph
 **/
}

/**
 * End files
 **/

})();
